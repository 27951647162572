<template>
  <video autoplay loop muted :width="width">
    <source :src="src" />
  </video>
</template>

<script>
export default {
  name: "Video",

  props: {
    src: { type: String, required: true },
    width: { type: Number, default: 400 },
  },
};
</script>
